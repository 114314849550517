import {
	apiAxios
} from '../axios'
import Europess from '../wangZhi'

let comm_api = Europess + '/api'

export default {
	// 实时汇率
	commonExchangeRate: data => apiAxios('post', comm_api + "/common/exchangeRate", data),
	// 发送验证码
	sendVerificationCode: data => apiAxios('post', comm_api + "/common/sendVerificationCode", data),
	// 上传文件
	uploadFile: data => apiAxios('post', comm_api + "/common/uploadFile", data),
	//比对验证码 
	checkVerificationCode: data => apiAxios('post', comm_api + "/common/checkVerificationCode", data),
	//文件url转换成文件流
  downloadFile: data => apiAxios('post', comm_api + "/common/downloadFile", data),
    //图片搜索分销商品上传base64  
    uploadImagePic: data => apiAxios('post', "https://api-landingpage.rakumart.cn/api/cross", data),
  // 图片翻译
  /**
   * 
   * @param {} data Object
   * @returns 
   */
  pictureTranslation:data=> apiAxios('post', comm_api + "/common/pictureTranslation", data),

}