<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- <el-button type="primary" @click="getfanyi">导出</el-button> -->
  </div>
</template>

<script>
// document.write(unescape("%3Cspan id='cnzz_stat_icon_1281191904'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/stat.php%3Fid%3D1281191904%26show%3Dpic' type='text/javascript'%3E%3C/script%3E"));
import language from './utlis/language2'
export default {
  data() {
    return {}
  },
  components: {},
  mounted() {
    window.addEventListener('load', () => {
      //写入你想要执行的代码
      if (
        this.$store.state.searchInfo !== '' &&
        this.$route.name !== 'CommoditySearch'
      ) {
        this.$store.state.searchInfo = ''
      }
    })
  },

  watch: {
    $route(to, from) {
      if (
        this.$store.state.searchInfo !== '' &&
        to.path !== '/commoditySearch'
      ) {
        this.$store.state.searchInfo = ''
      }
    },
  },
  created() {
    document.querySelector('#cnzz_stat_icon_1281191904').style.position = 'fixed';
    document.querySelector('#cnzz_stat_icon_1281191904').style.bottom = '0'
    if (this.$store.state.goodsList.length == 1) {
      this.$api
        .getShopGroup(this.form)
        .then((result) => {
          this.lists = result.data
          this.$store.commit('getgoodsList', result.data)
          this.allListShow()
        })
        .catch((err) => { })
    }
    // 预加载登录页面图片
    let image = new Image()

    image.src = require('./assets/login/bg.png')

    this.commonExchangeRate()
    if (localStorage.getItem('user_token')) {
      this.$api.EuropegetUserInfo().then((res) => {
        // 登录失效
        if (res.code != 0) {
          this.$store.commit('userData', null)
          localStorage.removeItem('user_token')
          // 有需要验证的用户中心
          let requireAuth = this.$route.matched[0].meta.requireAuth
          if (requireAuth) {
            this.$fun.toPage('/')
          }
          return
        } else {
          return this.$store.commit('userData', res.data) // 用户信息存储
        }
      })
    } else {
      this.$store.commit('userData', null)
      localStorage.removeItem('user_token')
    }
  },
  methods: {
    fanyi() {
      localStorage.removeItem('fanyi')
      this.$forceUpdate()
    },
    commonExchangeRate() {
      // 获取汇率
      this.$api.commonExchangeRate().then((res) => {
        this.$store.commit('getexchangeRate', res.data)
      })
    },
    getfanyi() {
      let list = []
      language.forEach((item) => {
        if (item.key == item.value) {
          list.push(item)
        }
      })
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/utlis/Export2Excel')
        const tHeader = ['中文', '英文翻译']
        const filterVal = ['key']

        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '美国站待翻译')
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },
  },
  mounted() {





  }
}
</script>
<style lang="scss">
@import './css/mixin';

/deep/.el-dialog__wrapper {
  .el-dialog {
    border-radius: 6px !important;
  }
}

html,
body,
#app {
  height: 100%;
  font-family: 'Roboto-Regular, Roboto';
}

/deep/ .el-input__inner {
  font-family: 'Roboto-Regular, Roboto';
  color: pink !important;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

.poinDone {
  pointer-events: none;

  &*,
  *,
  *::before {
    pointer-events: none;
  }
}

input[type='checkbox'] {
  $checkBoxSize: 20px;

  width: $checkBoxSize;
  height: $checkBoxSize;
  border-radius: 50%;
  border: 1px solid #888888;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background: #ff730b;
    border: none;
  }

  &:checked::after {
    content: '';

    background: transparent;
    border: #fff solid 1.5px;
    border-top: none;
    border-right: none;
    height: 2px;
    width: 40%;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg) translate(1px, -1px);
  }
}

button {
  cursor: pointer;
  transition: 0.3s;
  background-color: transparent;
}

.anniu {
  border: solid;
  position: fixed;
  width: 100px;
  height: 100px;
  left: 0px;
  z-index: 55999999;
}

// 去除弹出框背景颜色等
.el-popover.el-popper.notHaveBackground {
  background-color: transparent;
  box-shadow: none;
  border: none;
  margin-top: 0;
  padding: 0;

  .popper__arrow {
    display: none;
  }
}

// 禁用状态下的组件字体颜色
.is-disabled .el-input__inner {
  color: #666666;
}
</style>
<style>
#cnzz_stat_icon_1281134212 {
  opacity: 0;
  height: 0;
}
</style>
