import index from './apis/index'
import order from './apis/order'
import user from './apis/user.js'
import product from './apis/product.js'
import opublic from './apis/public'
 const api= {
    ...index,
    ...order,
    ...user,
    ...product,
    ...opublic
}

export default api 
